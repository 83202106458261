<template>
  <div>
    <div class="nav-container">
      <ul class="reset horizontal tab-header">
        <li v-for="tab in tabs" :class="{ 'active' : tab.selected }">
          <a @click.prevent.stop="selectTab(tab)" href="#">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabs: []
      };
    },

    created() {
      this.tabs = this.$children;
    },

    methods: {
      selectTab(selectedTab) {
        this.tabs.forEach(tab => {
          tab.selected = (tab.name == selectedTab.name);
        })
      }
    }
  };
</script>
