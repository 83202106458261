import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import Navigation from '../src/components/about-us/navigation/navigation.vue';
import Tabs from '../src/components/about-us/tabs/tabs.vue';
import Tab from '../src/components/about-us/tabs/tab.vue';

Vue.use(VueCarousel);

Array.from(document.getElementsByClassName('carousel-container')).forEach(element => {
  new Vue({}).$mount(element);
});

Array.from(document.getElementsByClassName('navigation-container')).forEach(element => {
  new Vue({
    components: {
      navigation: Navigation,
    },
  }).$mount(element);
});

Array.from(document.getElementsByClassName('tabs-container')).forEach(element => {
  new Vue({
    components: {
      navigation: Navigation,
      tabs: Tabs,
      tab: Tab,
    },
  }).$mount(element);
});
