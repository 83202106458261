<template>
  <div v-show="selected"><slot></slot></div>
</template>

<script>
  export default {
    data() {
      return {
        selected: this.active
      };
    },

    props: {
      name: {
        required: true,
        type: String,
      },
      active: {
        default: false,
        required: false,
        type: Boolean
      }
    }
  };
</script>
