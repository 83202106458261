<template>
  <div class="large-6 medium-8">
    <div class="hide-for-large hide-for-medium ham-container">
      <div class="ham-menu" :class="{ 'hide-for-small': !isHidden }" @click="toggleMenu">
        <i class="material-icons">menu</i>
      </div>
      <div class="close-menu" :class="{ 'hide-for-small': isHidden }" @click="toggleMenu">
        <i class="material-icons">close</i>
      </div>
    </div>
    <ul class="reset horizontal navigation" :class="{ 'hide-for-small': isHidden }" @click="toggleMenu">
      <li>
        <a href="/about-us">About</a>
      </li>
      <li>
        <a href="/about-us/advertise">Become a Partner</a>
      </li>
      <li>
        <a href="/about-us/careers">Careers</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isHidden: true,
      };
    },

    methods: {
      toggleMenu() {
        this.isHidden = !this.isHidden;
      },
    },
  };
</script>
